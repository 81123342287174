export const baseUrl = 'https://devapi.getstartbuild.com';

const siteUrls = {
    auth: {
        mobileSignup: `${baseUrl}/otp/send/builder`,
        mobileSignupVerify: `${baseUrl}/otp/verify`,
    },
    plans: {
        index: `${baseUrl}/auth/getPlanAndPackage`,
        plan: `${baseUrl}/auth/subscriptionPlan`,
    },
    time: {
        index: `${baseUrl}/auth/appointmentType`,
    },
    demo: {
        post: `${baseUrl}/book/appointment`
    }
};

export default siteUrls;

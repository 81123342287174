import React from 'react'
import { BsArrowRight } from 'react-icons/bs'
import img1 from '../assets/images/footer/communication_15047435 1.png'
import img2 from '../assets/images/footer/communication_15047516 1.png'
import img3 from '../assets/images/footer/connection_15047337 (1) 1.png'
import { BiCopyright, BiMapPin, BiPhoneCall } from 'react-icons/bi'
import { MdEmail } from 'react-icons/md'

function Footer() {
    return (
        <div className='px-[8%] py-[5%] pb-[7%] bg-section1'>
            <div className='flex justify-between flex-wrap gap-10 md:gap-5'>
                <div>
                    <div class="text-3xl lg:text-2xl xl:text-3xl w-fit font-medium text-primary">
                        GET START BUILD
                    </div>
                    <p className='text-sm xl:text-base w-[300px] xl:w-[400px] leading-7 mt-5'>Since 2023, GET START BUILD has been one of Bengaluru's top home builders. In addition to designing and planning buildings</p>
                    <p className='text-sm xl:text-base mt-14 xl:mt-20'>Copyright @ GET START BUILD</p>
                </div>
                <div>
                    <div class="text-3xl lg:text-2xl xl:text-3xl w-fit font-medium text-primary">
                        QUICK LINKS
                    </div>
                    <div className=' text-sm xl:text-base mt-5'>
                        <p className='cursor-pointer mb-5'>Home</p>
                        <p className='cursor-pointer mb-5'>Service</p>
                        <p className='cursor-pointer mb-5'>Calculator</p>
                        <p className='cursor-pointer mb-5'>About</p>
                        <p className='cursor-pointer mb-5'>Contact</p>
                    </div>
                </div>
                <div>
                    <div class="text-3xl lg:text-2xl xl:text-3xl w-fit font-medium text-primary">
                        RESOURCES
                    </div>
                    <div className=' text-sm xl:text-base  mt-5'>
                        <p className='cursor-pointer mb-5'>Blogs</p>
                        <p className='cursor-pointer mb-5'>Case Study</p>
                        <p className='cursor-pointer mb-5'>News & Media</p>
                        <p className='cursor-pointer mb-5'>About</p>
                    </div>
                </div>
                <div>
                    <div class="text-3xl lg:text-2xl xl:text-3xl w-fit font-medium text-primary">
                        JOIN WITH US
                    </div>
                    <div className='mt-5 flex items-center'>
                        <input className='h-12 xl:h-14 w-full border-[1px] border-secondary bg-transparent px-5' type="text" placeholder='Enter Your Email' />
                        <button className='bg-primary text-white border-none px-3 ms-1 flex items-center h-12 xl:h-14'>Submit <span className='ms-2'><BsArrowRight size={20} /></span></button>
                    </div>
                    <div className='flex items-center gap-8 mt-5'>
                        <img className='h-10 cursor-pointer' src={img1} alt="img1" />
                        <img className='h-10 cursor-pointer' src={img2} alt="img2" />
                        <img className='h-10 cursor-pointer' src={img3} alt="img3" />
                    </div>
                </div>
            </div>
            <div className='w-full h-[1px] bg-[#B3B3B3] mt-5'></div>
            <div className='mt-5 flex justify-between flex-wrap gap-5 md:gap-8 items-center'>
                <div className='flex items-center'>
                    <span className='text-primary'><BiCopyright size={25} /></span>
                    <p className=' ms-1 mt-1 text-sm xl:text-base'>2023 www.getstartbuild.com All rights reserved</p>
                </div>
                <div className='flex items-center'>
                    <span className='text-primary'><MdEmail size={25} /></span>
                    <p className=' ms-1 mt-1 text-sm xl:text-base'>contact@getstartbuild</p>
                </div>
                <div className='flex items-center'>
                    <span className='text-primary'><BiMapPin size={25} /></span>
                    <p className=' ms-1 mt-1 text-sm xl:text-base'>BTM, Layout, Bengaluru</p>
                </div>
                <div className='flex items-center'>
                    <span className='text-primary'><BiPhoneCall size={25} /></span>
                    <p className=' ms-1 mt-1 text-sm xl:text-base'>91+ 9500452312</p>
                </div>
            </div>
        </div>
    )
}

export default Footer

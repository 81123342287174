import axios from "axios";
import siteUrls from "../api-config/ApiConfig";
import toast from "react-hot-toast";

export const getDemoTime = async (query) => {
    const response = await axios.get(`${siteUrls.time.index}${query}`);
    return response
};

export const bookDemoApi = async (payload) => {
    try {
        const bookDemo = await axios.post(siteUrls.demo.post, payload);
        return bookDemo;
    } catch (error) {
        console.error('Error booking demo:', error);
        toast.error(error.response.data.msg)
    }
};
import React, { useEffect, useState } from 'react'
import Calendar from './Calendar'
import { bookDemoApi, getDemoTime } from '../../api/bookDemo';
import { useQuery } from '@tanstack/react-query';
import { convertTo12HourFormat, formatDisplayDate } from '../../utils/helper';
import { MdEdit } from 'react-icons/md';
import { FaMapMarkerAlt } from "react-icons/fa";
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CgChevronLeft } from 'react-icons/cg';
import { Button, Spinner } from 'flowbite-react';
import OtpModal from '../../components/OtpModal';
import { mobileOTPSignup } from '../../api/auth';
import toast from 'react-hot-toast';
import { BiCheckCircle, BiSolidCheckCircle } from 'react-icons/bi';
import SuccessModal from '../../components/SuccessModal';

function BookDemo() {
    const [openModal, setOpenModal] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(currentDate);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [formattedDate, setFormattedDate] = useState();
    const [previousNumber, setPreviousNumber] = useState();
    const [displayFormattedDate, setDisplayFormattedDate] = useState();
    const [selectedTime, setSelectedTime] = useState();
    const [isTimeSelected, setIsTimeSelected] = useState(false);

    const getDemo = useQuery({
        queryKey: ['getDemoTime', formattedDate],
        queryFn: () => getDemoTime(`?date=${formattedDate}`),
    });

    const toggleModal = () => {
        setOpenModal(!openModal)
    }
    const toggleSuccessModal = () => {
        setOpenSuccessModal(!openSuccessModal)
    }

    const schema = yup.object().shape({
        firstName: yup.string().required('This field is required. Please enter a value'),
        lastName: yup.string().required('This field is required. Please enter a value'),
        mobile: yup.string().required('Mobile number is required').matches(/^\d{10}$/, "Mobile number should be 10 digits"),
        email: yup.string().required('Email is required').email('Email is invalid'),
        // description: yup.string(),
    });

    const { register, handleSubmit, control, setValue, watch, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        const year = selectedDate.getFullYear();
        const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
        const day = String(selectedDate.getDate()).padStart(2, '0');
        const formatted = `${year}-${month}-${day}`;
        setFormattedDate(formatted);
    }, [selectedDate]);

    useEffect(() => {
        setDisplayFormattedDate(formatDisplayDate(selectedDate));
    }, [selectedDate]);

    const handleDate = (date) => {
        setSelectedDate(date)
    }

    const handleSelectedTime = (time) => {
        setSelectedTime(time)
        setIsTimeSelected(true)
    }

    const mobile = watch('mobile')
    const token = localStorage.getItem('access-token')
    const userId = localStorage.getItem('userId')
    const verifiedMobile = localStorage.getItem('mobile')
    console.log(token, userId);

    const mobileOTPSend = async (number) => {
        const payload = {
            mobile: number,
        }
        const updateApi = await mobileOTPSignup(payload)
        if (updateApi) {
            setOpenModal(true)
        } else {
            toast.error('Something went wrong!!!')
        }
    }

    useEffect(() => {
        if (mobile && mobile.length === 10 && previousNumber !== mobile) {
            setPreviousNumber(mobile)
            mobileOTPSend(mobile)
        }
    }, [mobile])


    const onSubmit = async (data) => {

        const formattedName = `${data.firstName} ${data.lastName}`
        const formatterAppointment = `${formattedDate}T${selectedTime}`
        const payload = {
            appointmentType: getDemo?.data?.data?.result[0]?._id,
            appointmentDate: formatterAppointment,
            name: formattedName,
            mobile: data.mobile,
            email: data.email,
            description: data.description
        }
        const updateApi = await bookDemoApi(payload)
        if (updateApi) {
            setOpenSuccessModal(true)
        }
    }

    const { isLoading, isError } = getDemo;

    if (isError) {
        return <div className='h-screen flex items-center justify-center'>Error: Unable to fetch data</div>;
    }

    return (
        <div className='px-[5%] xl:px-[8%] pt-32 py-[5%]'>
            <h1 className='font-medium text-2xl xl:text-3xl text-center mt-10 xl:mt-16'>Discover the Features of GSB with Our Personalized Demo</h1>
            <p className='mt-3 xl:mt-5 text-center text-sm xl:text-base text-gray-500'>Reserve your spot for a personalized demo and experience how our software can revolutionize your construction projects.</p>
            <div className='lg:px-[15%] xl:px-[18%] mt-10'>
                <div className='grid grid-cols-12 shadow-calendar'>
                    {isTimeSelected ? (
                        <div className='col-span-12 p-6 lg:p-10'>
                            <h1 className='text-xl font-medium'>Your information</h1>
                            <div className='flex items-center mt-4'>
                                <h1 className=' font-medium text-lg'>{displayFormattedDate}, {convertTo12HourFormat(selectedTime)}</h1>
                                <span onClick={() => setIsTimeSelected(false)} className='flex items-center ms-3 text-gray-500 cursor-pointer'><span className='me-1'><MdEdit /></span> Edit</span>
                            </div>
                            <span className='flex items-center text-sm mt-1 text-gray-500 cursor-pointer'><span className='me-1'><FaMapMarkerAlt /></span> Google Meet</span>
                            <div className='mt-8'>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='grid grid-cols-12 gap-5'>
                                        <div className='col-span-12 md:col-span-6'>
                                            <div className='font-medium text-sm'>
                                                First Name<span className='text-danger-500 ms-1'>*</span>
                                            </div>
                                            <div className="mt-1">
                                                <input
                                                    id='firstName'
                                                    type="text"
                                                    className='bg-gray-100 border-[1px] border-gray-300 rounded-sm w-full'
                                                    {...register('firstName')}
                                                />
                                                {errors.firstName && <p className='text-red-500 text-xs mt-1 font-medium'>{errors.firstName.message}</p>}
                                            </div>
                                        </div>
                                        <div className='col-span-12 md:col-span-6'>
                                            <div className='font-medium text-sm'>
                                                Last Name<span className='text-danger-500 ms-1'>*</span>
                                            </div>
                                            <div className="mt-1">
                                                <input
                                                    id='lastName'
                                                    type="text"
                                                    className='bg-gray-100 border-[1px] border-gray-300 rounded-sm w-full'
                                                    {...register('lastName')}
                                                />
                                                {errors.lastName && <p className='text-red-500 text-xs mt-1 font-medium'>{errors.lastName.message}</p>}
                                            </div>
                                        </div>
                                        <div className='col-span-12 '>
                                            <div className='font-medium text-sm'>
                                                Mobile<span className='text-danger-500 ms-1'>*</span>
                                            </div>
                                            <div className="mt-1">
                                                <div className='relative'>
                                                    <input
                                                        id='mobile'
                                                        type="text"
                                                        className='bg-gray-100 border-[1px] border-gray-300 rounded-sm w-full'
                                                        {...register('mobile')}
                                                    />
                                                    {mobile === verifiedMobile && userId && (
                                                        <span className='text-green-500 text-lg absolute right-3 top-[30%]'><BiSolidCheckCircle /></span>
                                                    )}
                                                </div>
                                                {errors.mobile && <p className='text-red-500 text-xs mt-1 font-medium'>{errors.mobile.message}</p>}
                                            </div>
                                        </div>
                                        <div className='col-span-12 '>
                                            <div className='font-medium text-sm'>
                                                Email<span className='text-danger-500 ms-1'>*</span>
                                            </div>
                                            <div className="mt-1">
                                                <input
                                                    id='email'
                                                    type="text"
                                                    className='bg-gray-100 border-[1px] border-gray-300 rounded-sm w-full'
                                                    {...register('email')}
                                                />
                                                {errors.email && <p className='text-red-500 text-xs mt-1 font-medium'>{errors.email.message}</p>}
                                            </div>
                                        </div>
                                        <div className='col-span-12'>
                                            <div className='font-medium text-sm'>
                                                How do you plan to use GSB? What's your specific use case?
                                            </div>
                                            <div className="mt-1">
                                                <textarea
                                                    id='description'
                                                    className='bg-gray-100 border-[1px] h-28 border-gray-300 rounded-sm w-full'
                                                    {...register('description')}
                                                />
                                                {errors.description && <p className='text-red-500 text-xs mt-1 font-medium'>{errors.description.message}</p>}
                                            </div>
                                        </div>
                                        <div className='col-span-12 flex justify-between items-center'>
                                            <button onClick={() => setIsTimeSelected(false)} className='border-[1px] border-gray-500 rounded-sm flex items-center text-sm font-medium gap-2 px-5 py-3'><CgChevronLeft /> Back</button>
                                            <Button type="submit" className='bg-primary rounded-sm'>Confirm</Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className='bg-primary py-20 col-span-12 md:col-span-6'>
                                <div className='flex justify-center'>
                                    <img className='h-20 w-20 rounded-full object-cover border-white border-2' src="https://samsel.s3.ap-south-1.amazonaws.com/deep/deeblue/get-start-bulid/file-17akn540bllt2sduqs.jpeg" alt="profile" />
                                </div>
                                <h1 className='mt-5 text-white text-center text-2xl'>Meet with GSB Team</h1>
                                <div className='p-3'>
                                    <Calendar handleDate={handleDate} date={selectedDate} />
                                </div>
                            </div>
                            <div className='bg-white col-span-12 md:col-span-6 p-6 h-fit lg:p-10'>
                                <h1 className='font-medium'>Meeting Duration</h1>
                                <div className='mt-3 bg-gray-200 text-gray-500 p-2 text-center text-sm rounded-sm border-[1px] border-gray-300'>
                                    30 mins
                                </div>
                                <div className='mt-8'>
                                    <h1 className='font-medium'>What time works best?</h1>
                                    <h1 className='text-gray-600 mt-1'>Showing times for <span className='font-medium text-black'>{displayFormattedDate}</span></h1>
                                </div>
                                {isLoading ? (
                                    <div className='mt-5 md:h-[400px] flex justify-center items-center'>
                                        <Spinner aria-label="Default status example" />
                                    </div>
                                ) : (
                                    <div className='mt-5 min-h-fit max-h-[400px] custom-scrollbar'>
                                        {getDemo?.data?.data?.result[0]?.availableTimings?.map((item, i) => (
                                            <div onClick={() => handleSelectedTime(item)} key={i} className={`bg-white border-[1px] cursor-pointer border-gray-300 hover:border-gray-500 text-gray-500 p-3 text-center mb-2 ${selectedTime === item ? 'border-gray-500' : ''}`}>
                                                {convertTo12HourFormat(item)}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </>
                    )
                    }
                </div >
            </div >
            <OtpModal isModal={openModal} toggle={toggleModal} mobile={mobile} verify={true} />
            {selectedDate && selectedTime && (
                <SuccessModal isModal={openSuccessModal} toggle={toggleSuccessModal} title={'Booking Successful'} description={'Your booking is done. Details will be sent through email. One of our demo executives will be in touch with you shortly.'} date={displayFormattedDate} time={selectedTime} />
            )}
        </div >
    )
}

export default BookDemo

import React from 'react'
import Marquee from 'react-fast-marquee'
import { navMockData } from './Navbar'
import { Link } from 'react-router-dom'
import feature from '../assets/images/feature.png'

function FeaturesBottom() {
    return (
        <div className='fixed bottom-0 left-0 right-0 w-full z-40 border-t-[1px] border-dashed border-slate-300'>
            <div className='relative w-full'>
                <Marquee
                    className="w-100 testing backdrop-blur-3xl py-2"
                    direction="left"
                    speed={50}
                    loop={0}
                    gradient={false}
                    pauseOnHover={true}
                >
                    {navMockData.map((item, i) => (
                        <div key={i} className='w-fit'>
                            <Link to={`/feature/${item.id}`}>
                                <div className='p-2 xl:p-3 rounded-2xl xl:rounded-3xl bg-white shadow flex items-center w-[160px] xl:w-[190px] min-w-fit ms-3 cursor-pointer'>
                                    <img className='h-8 xl:h-[43px]' src={item.icon} alt="icon" />
                                    <h1 className='w-1/2 ms-2 font-medium text-xs xl:text-sm'>{item.title}</h1>
                                </div>
                            </Link>
                        </div>
                    ))}
                </Marquee>
                <img className='absolute -top-[45%] -left-[25%] md:-left-[35%] lg:-left-[40%] xl:-top-[48%] xl:-left-[40%] z-50 h-12 xl:h-16 w-full object-contain' src={feature} alt="title" />
            </div>
        </div>
    )
}

export default FeaturesBottom


// Lead 
import banner1 from '../assets/images/featuresTab/Group 1597882953.png'
import f11 from '../assets/images/viewFeatures/lead/Group 1597883051.png'
import f12 from '../assets/images/viewFeatures/lead/Group 1597883052.png'
import f13 from '../assets/images/viewFeatures/lead/Group 1597883053.png'
import title1 from '../assets/images/title/Group 1597883176.png'
import vector1 from '../assets/images/bannerFeature/lead1.png'

// Proposal
import banner2 from '../assets/images/featuresTab/GSB (2) 3.png'
import f21 from '../assets/images/viewFeatures/proposal/Group 1597883054.png'
import f22 from '../assets/images/viewFeatures/proposal/Group 1597883055.png'
import f23 from '../assets/images/viewFeatures/proposal/Group 1597883056.png'
import title2 from '../assets/images/title/Group 1597883177.png'
import vector2 from '../assets/images/bannerFeature/proposal.png'

// Project
import banner3 from '../assets/images/featuresTab/Group 1597882178.png'
import f31 from '../assets/images/viewFeatures/project/Group 1597883065.png'
import f32 from '../assets/images/viewFeatures/project/Group 1597883066 (1).png'
import f33 from '../assets/images/viewFeatures/project/Group 1597883067.png'
import title3 from '../assets/images/title/Group 1597883178.png'
import vector3 from '../assets/images/bannerFeature/project.png'

// Material
import banner4 from '../assets/images/featuresTab/WhatsApp Image 2024-07-23 at 17.47.06 1.png'
import f41 from '../assets/images/viewFeatures/material/Group 1597883070.png'
import f42 from '../assets/images/viewFeatures/material/Group 1597883079.png'
import f43 from '../assets/images/viewFeatures/material/Group 1597883082.png'
import title4 from '../assets/images/title/Group 1597883179.png'
import vector4 from '../assets/images/bannerFeature/material.png'

// HR
import banner5 from '../assets/images/featuresTab/Group 1597882892.png'
import f51 from '../assets/images/viewFeatures/hr/Group 1597883094.png'
import f52 from '../assets/images/viewFeatures/hr/Group 1597883095.png'
import f53 from '../assets/images/viewFeatures/hr/Group 1597883096.png'
import title5 from '../assets/images/title/Group 1597883180.png'
import vector5 from '../assets/images/bannerFeature/hr.png'

// Vendor
import banner6 from '../assets/images/featuresTab/GSB (84) 1.png'
import f61 from '../assets/images/viewFeatures/vendor/Group 1597883098.png'
import f62 from '../assets/images/viewFeatures/vendor/Group 1597883099.png'
import f63 from '../assets/images/viewFeatures/vendor/Group 1597883103.png'
import title6 from '../assets/images/title/Group 1597883177 (1).png'
import vector6 from '../assets/images/bannerFeature/vendor.png'

// Inventory
import banner7 from '../assets/images/featuresTab/GSB (4) 2.png'
import f71 from '../assets/images/viewFeatures/inventory/Group 1597883108.png'
import f72 from '../assets/images/viewFeatures/inventory/Group 1597883109.png'
import f73 from '../assets/images/viewFeatures/inventory/Group 1597883110.png'
import title7 from '../assets/images/title/Group 1597883177 (2).png'
import vector7 from '../assets/images/bannerFeature/inventory (2).png'

// Achitecture
import banner8 from '../assets/images/featuresTab/GSB (5) 2.png'
import f81 from '../assets/images/viewFeatures/architecture/Group 1597883133.png'
import title8 from '../assets/images/title/Group 1597883177 (3).png'
import vector8 from '../assets/images/bannerFeature/architecture.png'

// Support
import banner9 from '../assets/images/featuresTab/Group 1597882982.png'
import f91 from '../assets/images/viewFeatures/support/Group 1597883134.png'
import title9 from '../assets/images/title/Group 1597883177 (4).png'
import vector9 from '../assets/images/bannerFeature/support.png'

// User
import banner10 from '../assets/images/featuresTab/GSB (6) 4.png'
import f101 from '../assets/images/viewFeatures/user/Group 1597883135.png'
import f102 from '../assets/images/viewFeatures/user/Group 1597883136.png'
import title10 from '../assets/images/title/Group 1597883177 (5).png'
import vector10 from '../assets/images/bannerFeature/user.png'

// Communication
import banner11 from '../assets/images/featuresTab/Group 1597883252.png'
import f113 from '../assets/images/viewFeatures/communication/Group 1597883147.png'
import f112 from '../assets/images/viewFeatures/communication/Group 1597883168.png'
import f111 from '../assets/images/viewFeatures/communication/Group 1597883169.png'
import title11 from '../assets/images/title/Group 1597883177 (6).png'
import vector11 from '../assets/images/bannerFeature/communication.png'

// Finance
import banner12 from '../assets/images/featuresTab/Group 1597882892.png'
import f121 from '../assets/images/viewFeatures/communication/Group 1597883147.png'
import f122 from '../assets/images/viewFeatures/communication/Group 1597883168.png'
import f123 from '../assets/images/viewFeatures/communication/Group 1597883169.png'
import title12 from '../assets/images/title/Group 1597883253.png'
import vector12 from '../assets/images/bannerFeature/finance (2).png'

export const featuresMockData = [
    {
        id: '94f7a1bc-90d9-4b7e-b39e-7d439ec5fcb8',
        title: 'Maximize Your Conversions with Intelligent Lead CRM Solutions',
        titleImg: title1,
        vector: vector1,
        description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
        banner: banner1,
        featureTitle: 'Head of CRM and Lead Management',
        features: [
            {
                heading: 'Assigned Requirements',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f11,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
            {
                heading: 'Add Lead',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f12,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
            {
                heading: 'Follow up',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f13,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
        ]
    },
    {
        id: '27e1d2c3-48e6-4c63-a7f0-f0b8de4b4a19',
        title: 'Simplify Proposal Creation and Tracking with Advanced Management',
        titleImg: title2,
        vector: vector2,
        description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
        banner: banner2,
        featureTitle: 'Head of proposal and proposal Management',
        features: [
            {
                heading: 'Proposal Budget',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f21,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
            {
                heading: 'Scope of work',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f22,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
            {
                heading: 'Payment Schedule',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f23,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
        ]
    },
    {
        id: 'd178a4be-6b98-4174-86f8-9ec89b1545b5',
        title: 'Take Control of Your Projects with Advanced Management Solutions',
        titleImg: title3,
        vector: vector3,
        description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
        banner: banner3,
        featureTitle: 'Head of project and project Management',
        features: [
            {
                heading: 'Task Management',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f31,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
            {
                heading: 'Assign Workers',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f32,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
            {
                heading: 'Project list',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f33,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
        ]
    },
    {
        id: '4c6a7cb2-f47b-4eb1-8358-87692b1eaf3d',
        title: 'Efficiently Manage Materials and Reduce Waste with Our Solutions',
        titleImg: title4,
        vector: vector4,
        description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
        banner: banner4,
        featureTitle: 'Head of Material and Material Management',
        features: [
            {
                heading: 'Project Site',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f41,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
            {
                heading: 'Material',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f42,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
            {
                heading: 'Material Request',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f43,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
        ]
    },
    {
        id: '91d7ab8c-65d6-40c1-82f6-c4971a6b60a9',
        title: 'Empower Your Workforce with Our Comprehensive HR Management',
        titleImg: title5,
        vector: vector5,
        description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
        banner: banner5,
        featureTitle: 'Head of HR and HR Management',
        features: [
            {
                heading: 'Employee',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f51,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
            {
                heading: 'Employee & Project Attendance',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f52,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
            {
                heading: 'Leave Management',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f53,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
        ]
    },
    {
        id: '9a8c6d7f-57c8-4c53-85c1-b4f4d239d3b1',
        title: 'Streamline Your Operations with Our Comprehensive Vendor Management Solution',
        titleImg: title6,
        vector: vector6,
        description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
        banner: banner6,
        featureTitle: 'Head of Vendor and Vendor Management',
        features: [
            {
                heading: 'Vendor List',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f62,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
            {
                heading: 'Vendor Materials',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f61,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
            {
                heading: 'Recent Orders',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f63,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
        ]
    },
    {
        id: '66b5a2f3-5a9b-4e2b-a46c-3d7b7b18c1e2',
        title: 'Revolutionize Your Supply Chain with Smart Inventory Solutions',
        titleImg: title7,
        vector: vector7,
        description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
        banner: banner7,
        featureTitle: 'Head of Inventory and Inventory Management',
        features: [
            {
                heading: 'Warehouse',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f71,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
            {
                heading: 'Warehouse History',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f72,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
            {
                heading: 'Purchase Order',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f73,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
        ]
    },
    {
        id: '7b6c5d3e-1e9b-4428-8765-921d9b4e6f4f',
        title: 'Streamline Design Processes with Comprehensive Architecture Management',
        titleImg: title8,
        vector: vector8,
        description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
        banner: banner8,
        featureTitle: 'Head of Architecture and Architecture Management',
        features: [
            {
                heading: 'Architecture Plan',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f81,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
        ]
    },
    {
        id: 'b3e7d8a9-2c5f-4d79-b8b1-4c8d2e3e5a6f',
        title: 'Elevate Customer Satisfaction with Superior Support Management',
        titleImg: title9,
        vector: vector9,
        description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
        banner: banner9,
        featureTitle: 'Head of Support and Support Management',
        features: [
            {
                heading: 'Ticket System',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f91,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
        ]
    },
    {
        id: '50c9e2d1-3b7f-47d9-8a1f-7c6e2b8c9d4e',
        title: 'Simplify Access and Control with Advanced User Management',
        titleImg: title10,
        vector: vector10,
        description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
        banner: banner10,
        featureTitle: 'Head of User and User Management',
        features: [
            {
                heading: 'User List',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f101,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
            {
                heading: 'Roles and Permissions',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f102,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
        ]
    },
    {
        id: '8d2b7e6a-4e5f-4c69-89a1-7b6d3e2f5a8c',
        title: 'Enhance Collaboration with Streamlined Communication Management',
        titleImg: title11,
        vector: vector11,
        description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
        banner: banner11,
        featureTitle: 'Head of Communication and Communication Management',
        features: [
            {
                heading: 'Single Chat',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f111,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
            {
                heading: 'Group Chat',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f112,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
            {
                heading: 'Profile',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f113,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
        ]
    },
    {
        id: '2f3b1c4d-5e6a-4b7c-8d9e-1f2a7c5b3e6f',
        title: 'Streamline Your Operations with Our Comprehensive Vendor Management Solution',
        titleImg: title12,
        vector: vector12,
        description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
        banner: banner12,
        featureTitle: 'Head of Finance and Finance Management',
        features: [
            {
                heading: 'Vendor List',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f121,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
            {
                heading: 'Vendor Materials',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f122,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
            {
                heading: 'Recent Orders',
                description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline.',
                img_url: f123,
                details: ["Real-time visibility of online and offline activity gives you accurate workforce metrics", "See who is actively working right now, and what is core and non-core work", "Ensure compliance with local laws and mitigate the risks of non-compliance", "Ensure compliance with local laws and mitigate the risks of non-compliance"]
            },
        ]
    },
]
import React, { useEffect } from 'react'
import { featuresData } from '../../constants/homeData'
import { BsArrowRight } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import Aos from 'aos';
import 'aos/dist/aos.css';

function FeaturesSection() {
    const navigate = useNavigate()

    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    return (
        <>
            <div className='px-[8%] pb-[5%]'>
                <div data-aos="fade-up" className='flex flex-col justify-center items-center'>
                    <h1 className='text-primary font-medium mb-3 text-2xl'>Features</h1>
                    <h1 className='md:w-3/4 lg:w-full text-2xl xl:text-3xl font-medium text-center'>Streamline Your Construction Management Effortlessly</h1>
                </div>
                <div className='px-[0%] md:px-[8%] lg:mt-10 xl:mt-16'>
                    <div className='grid grid-cols-12 gap-5 md:gap-8 lg:gap-16 xl:gap-20 mt-8 md:mt-5 lg:mt-0'>
                        {featuresData.map((item, i) => (
                            <React.Fragment key={i}>
                                {((i + 1) % 2 === 0) ? (
                                    <>
                                        <div className='col-span-12 lg:col-span-6 hidden lg:block'>
                                            <img data-aos="fade-up" className='object-contain w-full' src={item.img_url} alt="img-sample" />
                                        </div>
                                        <div className='col-span-12 lg:col-span-6 flex justify-center items-center'>
                                            <div >
                                                <img className='h-10 object-contain mt-5' src={item?.icon} alt="icon" />
                                                <h1 className='font-medium text-secondary text-xl md:text-2xl xl:text-3xl mt-2 md:mt-5'>{item.title}</h1>
                                                <p className='leading-6 xl:leading-8 text-sm xl:text-lg mt-3 md:mt-4 text-slate-500'>{item.description}</p>
                                                <span onClick={() => navigate(`/feature/${item?.id}`)} className='cursor-pointer mt-2 md:mt-5 flex items-center text-secondary hover:text-primary font-medium'>Know more <span className='ms-1'><BsArrowRight /></span></span>
                                                <img className='object-contain mt-6 w-full lg:hidden' src={item.img_url} alt="img-sample" />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className='col-span-12 lg:col-span-6 flex items-center justify-center'>
                                            <div>
                                                <img className='h-10 object-contain mt-5' src={item?.icon} alt="icon" />
                                                <h1 className='font-medium text-secondary text-xl md:text-2xl xl:text-3xl mt-2 md:mt-5'>{item.title}</h1>
                                                <p className='leading-6 xl:leading-8 text-sm xl:text-lg mt-3 md:mt-4 text-slate-500'>{item.description}</p>
                                                <span onClick={() => navigate(`/feature/${item?.id}`)} className='cursor-pointer mt-2 md:mt-5 flex items-center text-secondary hover:text-primary font-medium'>Know more <span className='ms-1'><BsArrowRight /></span></span>
                                            </div>
                                        </div>
                                        <div className='col-span-12 lg:col-span-6'>
                                            <img data-aos="fade-up" className='object-contain w-full' src={item.img_url} alt="img-sample" />
                                        </div>
                                    </>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default FeaturesSection

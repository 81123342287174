import React, { useState } from 'react'
import './home.css';
import bannerImg from '../../assets/images/banner/bannerfinal.png';
import reviewImg from '../../assets/images/banner/Frame 1597885551.png';
import star from '../../assets/images/banner/Frame 1597885552.png';
import OtpModal from '../../components/OtpModal';
import { mobileOTPSignup } from '../../api/auth';
import toast from 'react-hot-toast';

function BannerSection() {
    const [openModal, setOpenModal] = useState(false);
    const [mobile, setMobile] = useState();

    const toggleModal = () => {
        setOpenModal(!openModal)
    }

    const otpTrigger = async () => {
        const data = {
            mobile: mobile,
        };
        if (mobile.length === 10) {
            const mobileStatus = await mobileOTPSignup(data);
            if (mobileStatus) {
                toast.success('OTP Send Successfully!');
                setOpenModal(true)
            }
        }
    }

    const handleInputChange = (e) => {
        const value = e.target.value;
        if (value.length <= 10) {
            setMobile(value);
        }
    };

    return (
        <>
            <div className='banner-section'>
                <div className='banner-container'>
                    <div className='banner-left-sec'>
                        <h1 className='banner-left-sec-title'>Transform Your Construction Projects with Our <span className='text-[#FFAE00]'>"</span><span className='font-normal underline'>All-in-One</span><span className='text-[#FFAE00]'>"</span> Management Software</h1>
                        <p className='banner-left-sec-description'>From lead CRM and proposal management to inventory and vendor management, our platform offers everything you need to ensure your projects run smoothly and efficiently.</p>
                        <div className='mt-3 flex'>
                            <input
                                onChange={handleInputChange}
                                value={mobile}
                                className='h-10 xl:h-12 w-[200px] text-sm md:w-[240px] xl:w-[300px]  rounded-l-md bg-transparent text-black border-[1px] border-black px-5'
                                type="number"
                                placeholder='Enter Your Number'
                                maxLength={10}
                            />
                            <button onClick={otpTrigger} className='bg-primary w-fit px-5 rounded-r-md h-10 xl:h-12 ms-1 boder-[1px] text-white text-xs xl:text-sm'>Get Start Free</button>
                        </div>
                        <div className='flex mt-4 xl:mt-6'>
                            <img className='h-8 lg:h-7 xl:h-10' src={reviewImg} alt="review-img" />
                            <div className='ms-4'>
                                <img className='h-4 lg:h-3 xl:h-5' src={star} alt="star-img" />
                                <p className='text-xs'>Trusted by 8k Builders</p>
                            </div>
                        </div>
                        <div className='mt-4 xl:mt-6 flex items-center gap-4 lg:gap-7 xl:gap-8'>
                            <div className='text-secondary'>
                                <h1 className='font-medium text-3xl xl:text-4xl mb-1'>12x</h1>
                                <p className='text-xs'>Faster Creation</p>
                            </div>
                            <div className='w-[1px] h-12 bg-gray-200'></div>
                            <div className='text-secondary'>
                                <h1 className='font-medium text-3xl xl:text-4xl mb-1'>35%</h1>
                                <p className='text-xs'>Wider Reach</p>
                            </div>
                            <div className='w-[1px] h-12 bg-gray-200'></div>
                            <div className='text-secondary'>
                                <h1 className='font-medium text-3xl xl:text-4xl mb-1'>60%</h1>
                                <p className='text-xs'>Cost Reduction</p>
                            </div>
                        </div>
                    </div>
                    <div className='banner-right-sec mt-10 lg:mt-0'>
                        <img className='object-contain w-full' src={bannerImg} alt="banner-img" />
                    </div>
                </div>
                {/* <div className='bg-slate-100 p-4 rounded-md mt-5'>
                    <div className='flex items-center overflow-auto custom-scrollbar'>
                        {navMockData.map((item, i) => (
                            <div key={i}>
                                <Link to={`/feature/${item.id}`}>
                                    <div className='flex  items-center w-[200px] cursor-pointer'>
                                        <img className='h-[43px] object-contain' draggable={false} src={item.icon} alt="icon" />
                                        <h1 className='ms-2 text-sm font-medium w-1/2'>{item.title}</h1>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div> */}

                <OtpModal isModal={openModal} toggle={toggleModal} mobile={mobile} verify={false} />
            </div>
        </>
    )
}

export default BannerSection

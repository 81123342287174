import axios from "axios";
import siteUrls from "../api-config/ApiConfig";
import toast from "react-hot-toast";

export const mobileOTPSignup = async (payload) => {
    try {
        const response = await axios.post(
            siteUrls.auth.mobileSignup,
            payload
        );
        return response;
    } catch (error) {
        toast.error(error.response.data.msg)
    }
};

export const mobileOTPSignUpVerify = async (payload) => {
    try {
        const response = await axios.post(
            siteUrls.auth.mobileSignupVerify,
            payload
        );
        return response;
    } catch (error) {
        toast.error(error.response.data.msg)
    }
};

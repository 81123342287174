import React, { useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa6';
// import leftImg from '../../assets/images/testimonial/Rectangle 34625577.png';
// import rightImg from '../../assets/images/testimonial/Rectangle 34625578.png';

function FaqSection() {
    const [selectedFaq, setSelectedFaq] = useState(2)

    const mockData = [
        {
            title: 'What are the benefits of installing solar panels ?',
            description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
        },
        {
            title: 'What maintenance is required for solar panels ?',
            description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
        },
        {
            title: 'Why is renewable energy important ?',
            description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
        },
        {
            title: 'Can i use solar panels to power my entire home ?',
            description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
        },
    ]

    return (
        <div className='px-[8%] py-[5%]'>
            <div className='flex justify-center items-center'>
                <div className='text-center'>
                    <h1 className='text-3xl lg:text-4xl xl:text-5xl text-[#222222] font-medium'>Frequently Asked Questions</h1>
                    <p className='text-base lg:text-lg xl:text-xl text-slate-600 mt-3'>Have No Worries, We Got You</p>
                </div>
            </div>
            <div className='lg:px-[16%] flex flex-col justify-center items-center mt-10 relative z-20'>
                {mockData?.map((item, i) => (
                    <div
                        key={i}
                        className={`w-full shadow px-3 py-8 rounded-md mb-5  transition-all duration-300 ${selectedFaq === i + 1 ? 'bg-primary text-white border-none' : 'bg-white'
                            }`}
                        style={{
                            transition: 'background-color 0.2s easeIn, color 0.3s easeIn',
                        }}
                    >
                        <div
                            onClick={() => setSelectedFaq(selectedFaq === i + 1 ? null : i + 1)}
                            className='flex justify-between items-center cursor-pointer'
                        >
                            <h1
                                className={`font-Roboto text-base xl:text-lg transition-colors duration-100 ${selectedFaq === i + 1 ? 'text-white' : ''
                                    }`}
                            >
                                {item?.title}
                            </h1>
                            <h1
                                className={`text-navydark transition-colors duration-100 ms-8 lg:ms-16 ${selectedFaq === i + 1 ? 'text-[#FFAE00]' : ''
                                    }`}
                            >
                                {selectedFaq === i + 1 ? <FaMinus /> : <FaPlus />}
                            </h1>
                        </div>
                        <div
                            className={`mt-2 w-3/4 transition-all duration-200 ${selectedFaq === i + 1 ? 'block' : 'hidden'
                                }`}
                        >
                            <p className='text-gray-400 text-sm xl:text-base'>{item?.description}</p>
                        </div>
                    </div>
                ))}
                {/* <img className='w-1/2 xl:w-1/3 absolute hidden md:block -left-10 lg:left-40 xl:left-44 -top-5 -z-10' src={leftImg} alt="leftImg" /> */}
                {/* <img className='w-1/2 xl:w-1/3 absolute hidden md:block -right-10 lg:right-40 xl:right-44 -bottom-5 -z-10' src={rightImg} alt="rightImg" /> */}
            </div>
        </div>
    )
}

export default FaqSection

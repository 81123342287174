import React, { useRef, useState } from 'react'
import { CgChevronDown } from 'react-icons/cg'
import { RxHamburgerMenu } from 'react-icons/rx'
import { Link, useNavigate } from 'react-router-dom'
import icon1 from '../assets/images/nav/Lead CRM.png'
import icon2 from '../assets/images/nav/Proposal Management.png'
import icon3 from '../assets/images/nav/Project Management.png'
import icon4 from '../assets/images/nav/Material Management.png'
import icon5 from '../assets/images/nav/Vendor Management.png'
import icon6 from '../assets/images/nav/Inventory Management.png'
import icon7 from '../assets/images/nav/Architecture Management.png'
import icon8 from '../assets/images/nav/Finance Management.png'
import icon9 from '../assets/images/nav/User Management.png'
import icon10 from '../assets/images/nav/HR Management.png'
import icon11 from '../assets/images/nav/Support Management.png'
import icon12 from '../assets/images/nav/Communication Management.png'

export const navMockData = [
    {
        id: '94f7a1bc-90d9-4b7e-b39e-7d439ec5fcb8',
        icon: icon1,
        title: 'Lead Management',
        description: 'Maximize Your Conversions with Intelligent Lead CRM Solutions',
        nav: '/lead-management',
        color: 'E7FFF8'
    },
    {
        id: '27e1d2c3-48e6-4c63-a7f0-f0b8de4b4a19',
        icon: icon2,
        title: 'Proposal Management',
        description: 'Maximize Your Conversions with Intelligent Lead CRM Solutions',
        nav: '/project-management',
        color: 'E8FCFF'
    },
    {
        id: 'd178a4be-6b98-4174-86f8-9ec89b1545b5',
        icon: icon3,
        title: 'Project Management',
        description: 'Maximize Your Conversions with Intelligent Lead CRM Solutions',
        nav: '/project-management',
        color: 'E9FCFF'
    },
    {
        id: '4c6a7cb2-f47b-4eb1-8358-87692b1eaf3d',
        icon: icon4,
        title: 'Material Management',
        description: 'Maximize Your Conversions with Intelligent Lead CRM Solutions',
        nav: '/project-management',
        color: 'FFF6F0'
    },
    {
        id: '9a8c6d7f-57c8-4c53-85c1-b4f4d239d3b1',
        icon: icon5,
        title: 'Vendor Management',
        description: 'Maximize Your Conversions with Intelligent Lead CRM Solutions',
        nav: '/project-management',
        color: 'F6EFFF'
    },
    {
        id: '66b5a2f3-5a9b-4e2b-a46c-3d7b7b18c1e2',
        icon: icon6,
        title: 'Inventory Management',
        description: 'Maximize Your Conversions with Intelligent Lead CRM Solutions',
        nav: '/project-management',
        color: 'FFF8EC'
    },
    {
        id: '7b6c5d3e-1e9b-4428-8765-921d9b4e6f4f',
        icon: icon7,
        title: 'Architecture Management',
        description: 'Maximize Your Conversions with Intelligent Lead CRM Solutions',
        nav: '/project-management',
        color: 'FCEDFF'
    },
    {
        id: '2f3b1c4d-5e6a-4b7c-8d9e-1f2a7c5b3e6f',
        icon: icon8,
        title: 'Finance Management',
        description: 'Maximize Your Conversions with Intelligent Lead CRM Solutions',
        nav: '/project-management',
        color: 'EDEFFF'
    },
    {
        id: '50c9e2d1-3b7f-47d9-8a1f-7c6e2b8c9d4e',
        icon: icon9,
        title: 'User Management',
        description: 'Maximize Your Conversions with Intelligent Lead CRM Solutions',
        nav: '/project-management',
        color: 'F4EDFF'
    },
    {
        id: '91d7ab8c-65d6-40c1-82f6-c4971a6b60a9',
        icon: icon10,
        title: 'HR Management',
        description: 'Maximize Your Conversions with Intelligent Lead CRM Solutions',
        nav: '/project-management',
        color: 'E9E6FF'
    },
    {
        id: 'b3e7d8a9-2c5f-4d79-b8b1-4c8d2e3e5a6f',
        icon: icon11,
        title: 'Support Management',
        description: 'Maximize Your Conversions with Intelligent Lead CRM Solutions',
        nav: '/project-management',
        color: 'E9EBFF'
    },
    {
        id: '8d2b7e6a-4e5f-4c69-89a1-7b6d3e2f5a8c',
        icon: icon12,
        title: 'Communication Management',
        description: 'Maximize Your Conversions with Intelligent Lead CRM Solutions',
        nav: '/project-management',
        color: 'FFEAEA'
    },
]

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate()

    const handleMouseEnter = () => {
        setIsOpen(true);
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
    };



    return (
        <div className='top-0 left-0 right-0 fixed z-[1000]'>
            <div className='backdrop-blur-sm'>
                <div className='px-[5%] xl:px-[5%] pt-7'>
                    <div className='bg-white border-[1px] border-slate-400 border-dashed px-5 lg:px-10 xl:px-12 py-4 xl:py-5 flex justify-between shadow-card rounded-lg'>
                        <div className='flex items-center'>
                            <Link to={'/'}>
                                <h1 className='font-bold text-secondary text-lg xl:text-xl'>Get Start Build</h1>
                            </Link>
                            <div className='hidden lg:block'>
                                <div className='flex items-center gap-8 ms-10'>
                                    <div
                                        className="relative inline-block z-[1111]"
                                        ref={dropdownRef}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <span className='flex text-xs cursor-pointer xl:text-sm items-center font-medium'>
                                            Features <span className='ms-1'><CgChevronDown /></span>
                                        </span>
                                        {isOpen && (
                                            <div className="absolute -left-32 xl:left-0 w-[1000px] p-5 pt-10 bg-white rounded-lg shadow-lg z-10">
                                                <div className='grid grid-cols-12 gap-4 gap-y-6'>
                                                    {navMockData.map((item, i) => (
                                                        <div onClick={() => {
                                                            navigate(`/feature/${item.id}`)
                                                            setIsOpen(false);
                                                        }} key={i} className='col-span-4'>
                                                            <div className='flex cursor-pointer'>
                                                                <div className=''>
                                                                    <img className='h-16 w-16 object-contain' src={item.icon} alt="icons" />
                                                                </div>
                                                                <div className='ms-4 mt-1'>
                                                                    <h1 className='text-sm font-medium'>{item.title}</h1>
                                                                    <p className='text-[10px] mt-1 text-gray-500'>{item.description}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <span className='flex text-xs xl:text-sm items-center font-medium'>How It Works</span>
                                    <span className='flex text-xs xl:text-sm items-center font-medium'>Price</span>
                                    <span className='flex text-xs xl:text-sm items-center font-medium'>Careers</span>
                                    <span className='flex text-xs xl:text-sm items-center font-medium'>More<span className='ms-1'><CgChevronDown /></span></span>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center'>
                            <a href="https://devapp.getstartbuild.com" target="_blank" rel="noopener noreferrer">
                                <button className='hidden lg:block border-[1px] border-primary bg-transparent px-5 py-2 rounded-md font-medium text-primary text-xs xl:text-sm w-fit h-full'>Login</button>
                            </a>
                            <Link to={'/schedule-demo'}>
                                <button className='hidden lg:block border-[1px] border-primary bg-primary ms-2 px-5 py-2 rounded-md font-medium text-white text-xs xl:text-sm w-fit h-full'>Schedule a demo</button>
                            </Link>
                            <span className='lg:hidden text-xl'><RxHamburgerMenu /></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar

import axios from "axios";
import siteUrls from "../api-config/ApiConfig";

export const getPlanAndPackage = async () => {
    const response = await axios.get(siteUrls.plans.index);
    return response
};
export const getPlanAndPackageNew = async () => {
    const response = await axios.get(siteUrls.plans.plan);
    return response
};
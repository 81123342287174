import React, { useEffect, useState } from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

function Calendar({ handleDate, date }) {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());
    const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());
    const [monthName, setMonthName] = useState(
        currentDate.toLocaleString('default', { month: 'long' })
    );
    const [selectedDate, setSelectedDate] = useState(date);
    const [formattedDate, setFormattedDate] = useState();

    useEffect(() => {
        const year = selectedDate.getFullYear();
        const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
        const day = String(selectedDate.getDate()).padStart(2, '0');
        const formatted = `${year}-${month}-${day}`;
        setFormattedDate(formatted);
    }, [selectedDate]);

    const isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0 && day !== 6; // Exclude Sundays and Saturdays
    };

    const getNextWeekdays = (startDate, daysCount) => {
        const dates = [];
        let currentDate = new Date(startDate);

        // Include the current date if it's a weekday
        if (isWeekday(currentDate)) {
            dates.push(new Date(currentDate));
        }

        while (dates.length < daysCount) {
            currentDate.setDate(currentDate.getDate() + 1);
            if (isWeekday(currentDate)) {
                dates.push(new Date(currentDate));
            }
        }

        return dates;
    };

    const selectableDates = getNextWeekdays(currentDate, 8);

    const currentMonthDate = new Date(currentYear, currentMonth, 1);

    const firstDayOfMonth = currentMonthDate.getDay();
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

    const prevMonthDays = firstDayOfMonth;
    const nextMonthDays = (7 - ((prevMonthDays + daysInMonth) % 7)) % 7;

    const calendarCells = [];

    // Render previous month days
    for (let i = prevMonthDays; i > 0; i--) {
        const prevMonthDate = new Date(currentYear, currentMonth, -i + 1);
        calendarCells.push(
            <div key={`prev-day-${i}`} className="py-0.5 rounded relative text-slate-500">
                {prevMonthDate.getDate()}
            </div>
        );
    }

    // Render current month days
    for (let i = 1; i <= daysInMonth; i++) {
        const date = new Date(currentYear, currentMonth, i);
        const isSelectable = selectableDates.some(
            (d) => d.getDate() === date.getDate() && d.getMonth() === date.getMonth() && d.getFullYear() === date.getFullYear()
        );
        const isCurrentDate = currentDate.getDate() === i && currentDate.getMonth() === currentMonth;
        const isSelectedDate =
            selectedDate && selectedDate.getDate() === i && selectedDate.getMonth() === currentMonth;
        const isNotCurrentOrSelected = !isCurrentDate && !isSelectedDate;

        let cellClassName = 'py-0.5 rounded relative';
        if (isNotCurrentOrSelected) {
            cellClassName += isSelectable ? ' hover:bg-primary/10 cursor-pointer' : ' text-slate-500';
        } else if (isSelectedDate) {
            cellClassName += ' bg-white text-black cursor-pointer';
        } else if (isCurrentDate) {
            cellClassName += 'text-white cursor-pointer';
        }

        calendarCells.push(
            <div
                key={`day-${i}`}
                className={cellClassName}
                onClick={isSelectable ? () => handleDateClick(i) : undefined}
            >
                {i}
            </div>
        );
    }

    // Render next month days
    for (let i = 1; i <= nextMonthDays; i++) {
        const nextMonthDate = new Date(currentYear, currentMonth + 1, i);
        calendarCells.push(
            <div key={`next-day-${i}`} className="py-0.5 rounded relative text-slate-500">
                {nextMonthDate.getDate()}
            </div>
        );
    }

    const handleDateClick = (day) => {
        const clickedDate = new Date(currentYear, currentMonth, day);
        setSelectedDate(clickedDate);
        handleDate(clickedDate)
    };

    const handleNextMonth = () => {
        const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;
        const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear;
        setCurrentMonth(nextMonth);
        setCurrentYear(nextYear);
        setMonthName(new Date(nextYear, nextMonth).toLocaleString('default', { month: 'long' }));
    };

    const handlePreviousMonth = () => {
        const prevMonth = currentMonth === 0 ? 11 : currentMonth - 1;
        const prevYear = currentMonth === 0 ? currentYear - 1 : currentYear;
        setCurrentMonth(prevMonth);
        setCurrentYear(prevYear);
        setMonthName(new Date(prevYear, prevMonth).toLocaleString('default', { month: 'long' }));
    };

    return (
        <>
            <div className="flex justify-center">
                <div className="flex items-center mt-2 w-fit">
                    <BiChevronLeft className="w-5 h-5 me-2 text-gray-200 cursor-pointer" onClick={handlePreviousMonth} />
                    <div className="text-2xl font-medium text-white w-fit">
                        {monthName} {currentYear}
                    </div>
                    <BiChevronRight className="w-5 h-5 ms-2 text-gray-200 cursor-pointer" onClick={handleNextMonth} />
                </div>
            </div>
            <div className="grid grid-cols-7 gap-4 mt-8 text-center text-white">
                <div className="font-medium">Su</div>
                <div className="font-medium">Mo</div>
                <div className="font-medium">Tu</div>
                <div className="font-medium">We</div>
                <div className="font-medium">Th</div>
                <div className="font-medium">Fr</div>
                <div className="font-medium">Sa</div>
                {calendarCells}
            </div>
        </>
    );
}

export default Calendar;

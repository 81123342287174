import { Button, Modal } from 'flowbite-react'
import React from 'react'
import { BsArrowRight, BsClock } from 'react-icons/bs'
import { CgCalendar, CgLock } from 'react-icons/cg'
import { FaRegCircleCheck } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import { convertTo12HourFormat } from '../utils/helper'
import { FaClock } from 'react-icons/fa'
import { FiClock } from 'react-icons/fi'

function SuccessModal({ isModal, toggle, title, description, date, time }) {
    return (
        <Modal className='z-[2000]' show={isModal} style={{ width: 'auto' }} onClose={toggle} popup>
            <Modal.Body>
                <div className="text-center flex flex-col justify-center items-center py-6 px-1 md:px-10">
                    <span className='text-green-500'><FaRegCircleCheck size={80} /></span>
                    <h1 className='text-2xl mb-3 mt-3 font-bold'>{title}</h1>
                    <p className='text-gray-500'>{description}</p>
                    <div className='flex items-center mt-3'>
                        <span><CgCalendar className='w-5 h-5 me-1 text-black' /></span>
                        <h1 className='font-medium text-gray-600 mt-[2px]'>{date}</h1>
                    </div>
                    <div className='flex items-center mt-1'>
                        <span><FiClock className='w-5 h-5 me-1 text-black' /></span>
                        <h1 className='font-medium text-gray-600 mt-[2px]'>{convertTo12HourFormat(time)}</h1>
                    </div>
                    <div className="flex justify-center mt-5">
                        <Link to={'/'}>
                            <Button className='flex items-center' color="dark" size='sm'>
                                Continue <BsArrowRight className='mt-1 ms-2' />
                            </Button>
                        </Link>
                    </div>
                </div>
            </Modal.Body>
        </Modal >
    )
}

export default SuccessModal

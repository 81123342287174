import './App.css';
import BannerSection from './pages/home/BannerSection';
import BenifitSection from './pages/home/BenifitSection';
import FaqSection from './pages/home/FaqSection';
import FeatureTab from './pages/home/FeatureTab';
import FeaturesSection from './pages/home/FeaturesSection';
import HowItWorks from './pages/home/HowItWorks';
import NewsLetterSection from './pages/home/NewsLetterSection';
import PackageAndPlan from './pages/home/PackageAndPlan';
import WhyChooseSection from './pages/home/WhyChooseSection';

function App() {
  return (
    <div>
      <BannerSection />
      {/* <WhyChooseSection /> */}
      <FeaturesSection />
      <FeatureTab />
      <PackageAndPlan />
      <BenifitSection />
      <FaqSection />
      <NewsLetterSection />
    </div>
  );
}

export default App;

import img3 from '../assets/images/features/Group 1597882975.png'
import img1 from '../assets/images/features/Group 1597882990.png'
import img2 from '../assets/images/features/Group 1597882993.png'
import img5 from '../assets/images/features/Group 1597882997 (2).png'
import img4 from '../assets/images/features/Group 1597882999.png'

import icon1 from '../assets/images/banner/icon 1.png';
import icon2 from '../assets/images/banner/icon- 2.png';
import icon3 from '../assets/images/banner/icon 3.png';

// Features Title 
import title1 from '../assets/images/title/Group 1597883176.png'
import title2 from '../assets/images/title/Group 1597883177.png'
import title3 from '../assets/images/title/Group 1597883178.png'
import title4 from '../assets/images/title/Group 1597883179.png'
import title5 from '../assets/images/title/Group 1597883180.png'


export const howItWorkData = [
    {
        icon: icon1,
        title: 'Choose Plan',
        description: 'capture and track from various sources in one place. monitor the progress of each lead through the sales pipeline. set reminders and automated follow-up emails to keep leads.'
    },
    {
        icon: icon2,
        title: 'Onboarding',
        description: 'capture and track from various sources in one place. monitor the progress of each lead through the sales pipeline. set reminders and automated follow-up emails to keep leads.'
    },
    {
        icon: icon3,
        title: 'Explore Features',
        description: 'capture and track from various sources in one place. monitor the progress of each lead through the sales pipeline. set reminders and automated follow-up emails to keep leads.'
    },
]
export const featuresData = [
    {
        id: '94f7a1bc-90d9-4b7e-b39e-7d439ec5fcb8',
        icon: title1,
        title: 'Maximize Your Conversions with Intelligent Lead CRM Solutions',
        description: 'Capture and track leads from various sources in one place. Monitor the progress of each lead through the sales pipeline. Set reminders and automated follow-up emails to keep leads.',
        img_url: img1,
    },
    {
        id: '27e1d2c3-48e6-4c63-a7f0-f0b8de4b4a19',
        icon: title2,
        title: 'Simplify Proposal Creation and Tracking with Advanced Management',
        description: 'Easily create and customize proposals for clients. Allow clients to review and approve proposals online.',
        img_url: img2,
    },
    {
        id: 'd178a4be-6b98-4174-86f8-9ec89b1545b5',
        icon: title3,
        title: 'Take Control of Your Projects with Advanced Management Solutions',
        description: 'Plan and schedule projects with Gantt charts and timelines. Assign and track tasks across your team. Monitor project progress and milestones in real-time.',
        img_url: img3,
    },
    {
        id: '4c6a7cb2-f47b-4eb1-8358-87692b1eaf3d',
        icon: title4,
        title: 'Efficiently Manage Materials and Reduce Waste with Our Solutions',
        description: 'Keep track of materials and inventory levels. Streamline the procurement process and manage suppliers. Monitor material costs and stay within budget.',
        img_url: img4,
    },
    {
        id: '91d7ab8c-65d6-40c1-82f6-c4971a6b60a9',
        icon: title5,
        title: 'Empower Your Workforce with Our Comprehensive HR Management',
        description: 'Maintain a comprehensive database of employee information. Automate payroll calculations and processing.',
        img_url: img5,
    },
]


export const tabData = [
    {
        name: 'Project Management',
        id: 1,
    },
    {
        name: 'Inventory Management',
        id: 3,
    },
    {
        name: 'Architecture Management',
        id: 4,
    },
    {
        name: 'Vendor Management',
        id: 5,
    },
]



import React, { useEffect, useRef, useState } from 'react';
import icon from '../assets/images/GET.png';
import { Link, useLocation } from 'react-router-dom';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import './Drawer.css'
import { mobileOTPSignup, mobileOTPSignUpVerify } from '../api/auth';
import toast from 'react-hot-toast';
import OTPInput from 'react-otp-input';
import { FaChevronLeft } from 'react-icons/fa6';

function Drawer() {
    const [isDrawer, setIsDrawer] = useState(false);
    const [isVerify, setIsVerify] = useState(false);
    const [mobile, setMobile] = useState();
    const [otp, setOtp] = useState();
    const mobileInputRef = useRef(null);
    const location = useLocation();

    const mobileSend = async () => {
        if (mobile.length === 10) {
            const data = {
                mobile: mobile,
            };
            const mobileStatus = await mobileOTPSignup(data);
            if (mobileStatus) {
                toast.success('OTP Send Successfully!');
                setIsVerify(true)
            }
        } else {
            toast.error("Mobile Number Must be 10 digit!")
        }
    }

    const otpVerify = async () => {
        const payload = {
            mobile: mobile,
            otp
        }
        const updateApi = await mobileOTPSignUpVerify(payload)
        if (updateApi) {
            const { accessToken, refreshToken } = updateApi?.data?.result?.tokens;
            const roleName = updateApi?.data?.result?.user?.role?.name;
            const step = updateApi?.data?.result?.user?.builderCurrentStep || 0;

            // Construct the URL with query parameters
            const newUrl = `https://devapp.getstartbuild.com/reconnect/builder/?accessToken=${encodeURIComponent(accessToken)}&refreshToken=${encodeURIComponent(refreshToken)}&roleName=${encodeURIComponent(roleName)}&step=${step}`;

            //Clearing state
            setMobile('')
            setOtp('')
            setIsVerify(false)
            setIsDrawer(false)

            // Open the URL in a new tab
            window.open(newUrl, '_blank');
        }
    }

    // useEffect(() => {
    //     if (location.pathname !== '/schedule-demo' && location.pathname !== '/') {
    //         setIsDrawer(true)
    //         if (mobileInputRef.current) {
    //             const timer = setTimeout(() => {
    //                 mobileInputRef.current.focus();
    //             }, 1000); // 2-second delay

    //             // Clear the timeout if the drawer is closed before the timer completes
    //             return () => clearTimeout(timer);
    //         }
    //     } else {
    //         setIsDrawer(false)
    //     }
    // }, [location.pathname]);

    useEffect(() => {
        if (isDrawer && mobileInputRef.current) {
            mobileInputRef.current.focus();
        }
    }, [isDrawer]);

    useEffect(() => {
        if (isVerify) {
            const timer = setTimeout(() => {
                const otpInputElements = document.querySelectorAll('.custom-otp-field2');
                if (otpInputElements.length > 0) {
                    otpInputElements[0].focus();
                }
            }, 200); // Small delay to ensure input is in the DOM

            return () => clearTimeout(timer);
        }
    }, [isVerify]);

    // useEffect(() => {
    //     if (location.pathname === '/' || location.pathname === '/schedule-demo') {
    //         const interval = setInterval(() => {
    //             setIsDrawer(true);
    //         }, 30000); // 20 seconds

    //         return () => clearInterval(interval);
    //     }
    // }, [location.pathname]);

    return (
        <div className={`fixed right-0 top-[30%] z-50 transition-transform duration-500 ${isDrawer ? 'translate-x-0' : 'translate-x-[330px]'}`}>
            <div className='w-[350px] pt-20 relative p-5 bg-white border-[1px] border-dashed border-slate-400 shadow-xl border-r-0 rounded-l-lg'>
                <img className='h-20 absolute top-0' src={icon} alt="icon" />
                <div>
                    <p className='text-xs font-medium'>From lead CRM and proposal management to inventory and vendor management, our platform offers everything you need to ensure your projects run smoothly and efficiently.</p>
                    {isVerify ? (
                        <div className='flex justify-center mt-3'>
                            <OTPInput
                                value={otp}
                                onChange={(e) => setOtp(e)}
                                inputType="tel"
                                numInputs={6}
                                inputStyle="custom-otp-field2"
                                renderInput={(props) => <input  {...props} />}
                            />
                        </div>
                    ) : (
                        <input ref={mobileInputRef} onChange={(e) => setMobile(e.target.value)} className='mt-5 border-[1px] border-slate-400 border-dashed w-full' type="number" placeholder='Enter Your Mobile' />
                    )}
                    <div className='flex justify-between items-center mt-3'>
                        <Link to={'/schedule-demo'}>
                            <span onClick={() => setIsDrawer(false)} className='text-sm underline hover:text-primary cursor-pointer'>Want Demo ?</span>
                        </Link>
                        {isVerify ? (
                            <div className='flex items-center'>
                                <span onClick={() => {
                                    setIsVerify(false)
                                    setOtp(null)
                                }} className='me-2 cursor-pointer'><FaChevronLeft size={15} /></span>
                                <button className='bg-primary w-fit p-3 px-3 text-white text-xs ' disabled={!otp || otp.length !== 6} onClick={otpVerify}>
                                    Verify OTP
                                </button>
                            </div>
                        ) : (
                            <button onClick={mobileSend} className='bg-primary w-fit p-3 px-3 text-white text-xs '>Start Now</button>
                        )}
                    </div>
                </div>
                <div onClick={() => setIsDrawer(!isDrawer)} className='bg-slate-300 hover:bg-slate-200 cursor-pointer h-10 w-10 absolute top-[40%] -left-8 rounded-md flex items-center justify-center '>
                    <span>{isDrawer ? <BsArrowRight size={20} /> : <span className='bounce-arrow'><BsArrowLeft size={20} /></span>}</span>
                </div>

            </div>
        </div>
    );
}

export default Drawer;

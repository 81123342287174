import React, { useEffect, useRef, useState } from 'react'
import { BsArrowRight } from 'react-icons/bs'
import { Link, useParams } from 'react-router-dom'
import { featuresMockData } from '../../constants/featuresData'
import FaqSection from '../home/FaqSection'
import NewsLetterSection from '../home/NewsLetterSection'
import { FaCheckDouble } from 'react-icons/fa6'
import process from '../../assets/images/viewFeatures/process.png'
import overview from '../../assets/images/viewFeatures/overview.png'

function Feature() {
    const [data, setData] = useState()
    const { id } = useParams()
    const sectionRefs = useRef([]);

    useEffect(() => {
        const filteredData = featuresMockData.find(item => item.id === id)
        setData(filteredData)
    }, [id])

    const handleTabClick = (index) => {
        const element = sectionRefs.current[index];
        const elementRect = element.getBoundingClientRect();
        const absoluteElementTop = elementRect.top + window.pageYOffset;
        const middle = absoluteElementTop - (window.innerHeight / 2) + (elementRect.height / 2);

        window.scrollTo({
            top: middle,
            behavior: 'smooth'
        });
    };

    return (
        <>
            <div className='bg-banner bg-cover border-b-[1px] border-dashed border-slate-300 w-full grid grid-cols-12 gap-5 px-[8%] pt-32 lg:pt-28 xl:pt-36  pb-7 xl:pb-10'>
                <div className='col-span-12  lg:col-span-7 flex flex-col justify-center items-start'>
                    <img className='h-10 lg:h-10  xl:h-12 mb-5' src={data?.titleImg} alt="title" />
                    <h1 className='text-2xl md:text-3xl xl:text-4xl w-full  font-semibold'>{data?.title}</h1>
                    <p className='w-full md:w-[70%]  text-sm xl:text-base mt-5'>{data?.description}</p>
                    <div className='flex items-center mt-5'>
                        <button className='bg-primary rounded-md text-white text-xs xl:text-sm p-3 px-6'>Get Started</button>
                        <Link to={'/schedule-demo'}>
                            <button className='ms-1 bg-white border-[1px] border-primary rounded-md text-xs xl:text-sm  p-3 px-6 flex items-center'>Request A Demo <span className='ms-1'><BsArrowRight /></span></button>
                        </Link>
                    </div>
                </div>
                <div className='col-span-12 lg:col-span-5 flex justify-center items-center'>
                    <img className='h-[300px] object-contain' src={data?.vector} alt="" />
                </div>
            </div>
            <div className='px-[8%] pt-10 pb-[5%]'>
                <div className='flex flex-col items-center justify-center'>
                    <img className='h-16 mb-1' src={process} alt="process" />
                    <div className='flex gap-4 overflow-y-auto'>
                        {data?.features?.map((item, i) => (
                            <div onClick={() => handleTabClick(i)} key={i} className={`cursor-pointer min-w-fit transition-all duration-300 ease-in border-[1px] rounded-3xl text-sm hover:bg-primary hover:text-white ${i === 0 ? 'bg-primary border-primary text-white' : 'bg-white border-primary'} p-2 px-5`}>
                                <h1>{item?.heading}</h1>
                            </div>
                        ))}
                    </div>
                    <div className='px-[0%] md:px-[8%]'>
                        <div className='grid grid-cols-12 gap-5 md:gap-8 lg:gap-16 xl:gap-20 mt-10'>

                            {data?.features.map((item, i) => (
                                <React.Fragment key={i}>
                                    {((i + 1) % 2 === 0) ? (
                                        <>
                                            <div ref={(el) => sectionRefs.current[i] = el} className='col-span-12 lg:col-span-6 hidden lg:block'>
                                                <img className='object-contain w-full' src={item?.img_url} alt="img-sample" />
                                            </div>
                                            <div className='col-span-12 lg:col-span-6 flex flex-col justify-center'>
                                                {/* <h1 className='font-medium text-primary text-lg xl:text-xl mt-10 lg:mt-0'>Features</h1> */}
                                                <h1 className='font-medium text-secondary text-xl md:text-2xl xl:text-3xl mt-2 md:mt-3'>{item?.heading}</h1>
                                                <div className='mt-5'>
                                                    {item?.details?.map((eachDetails) => (
                                                        <div className='flex items-start mb-4'>
                                                            <span className='text-green-500 mt-2 me-5'><FaCheckDouble size={25} /></span>
                                                            <p className='leading-6 xl:leading-8 text-sm xl:text-lg  text-slate-500'>{eachDetails}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                                <img className='object-contain mt-6 w-full lg:hidden' src={item?.img_url} alt="img-sample" />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div ref={(el) => sectionRefs.current[i] = el} className='col-span-12 lg:col-span-6 flex flex-col justify-center'>
                                                <h1 className='font-medium text-secondary text-xl md:text-2xl xl:text-3xl mt-2 md:mt-3'>{item?.heading}</h1>
                                                <div className='mt-5'>
                                                    {item?.details?.map((eachDetails) => (
                                                        <div className='flex items-start mb-4'>
                                                            <span className='text-green-500 mt-2 me-5'><FaCheckDouble size={25} /></span>
                                                            <p className='leading-6 xl:leading-8 text-sm xl:text-lg  text-slate-500'>{eachDetails}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className='col-span-12 lg:col-span-6'>
                                                <img className='object-contain w-full' src={item?.img_url} alt="img-sample" />
                                            </div>
                                        </>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                        <div className='flex justify-center items-center mt-10'>
                            <img className='h-16' src={overview} alt="process" />
                        </div>
                        <img className='object-contain w-full mt-5' src={data?.banner} alt="banner" />
                    </div>
                </div>
            </div >
            <FaqSection />
            <NewsLetterSection />
        </>
    )
}

export default Feature


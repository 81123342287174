import React, { useState } from 'react'
import { BsArrowUpRight } from 'react-icons/bs'
import { useQuery } from '@tanstack/react-query';
// import icon from '../../assets/images/banner/Group 1597882919.png'
import { FaChevronDown, FaChevronUp, FaCircleCheck } from 'react-icons/fa6';
import { getPlanAndPackage, getPlanAndPackageNew } from '../../api/packagePlan';

function PackageAndPlan() {
    const [activePackage, setActivePackage] = useState();
    const [activePackage2, setActivePackage2] = useState();
    const [activePackage3, setActivePackage3] = useState();

    const getPlanData = useQuery({
        queryKey: ['getPlan'],
        queryFn: () => getPlanAndPackage(),
    });
    const getPlanDataNew = useQuery({
        queryKey: ['getPlannew'],
        queryFn: () => getPlanAndPackageNew(),
    });

    // console.log(getPlanDataNew.data.data.result);

    const handleToggle = (id) => {
        if (id === activePackage) {
            setActivePackage(null)
        } else {
            setActivePackage(id)
        }
    }
    const handleToggle2 = (id) => {
        if (id === activePackage2) {
            setActivePackage2(null)
        } else {
            setActivePackage2(id)
        }
    }
    const handleToggle3 = (id) => {
        if (id === activePackage3) {
            setActivePackage3(null)
        } else {
            setActivePackage3(id)
        }
    }

    return (
        <div className='px-[8%] py-[5%] bg-section2'>
            <div>

            </div>
            <div className='flex justify-between items-start md:items-center'>
                <div className='w-3/4'>
                    <h1 className='text-3xl lg:text-3xl xl:text-5xl text-[#222222] font-medium'>Our Construction Packages</h1>
                    <p className='text-base lg:text-lg xl:text-xl text-slate-600 mt-3'>is a long established fact that a reader will be distracted by the readable. </p>
                </div>
                <div className='h-14 w-14  xl:h-16 xl:w-16 cursor-pointer group bg-primary text-white rounded-full flex justify-center items-center'>
                    <span className='text-2xl xl:text-3xl group-hover:xl:text-4xl group-hover:lg:text-3xl transition-all duration-200 ease-out'><BsArrowUpRight /></span>
                </div>
            </div>
            <div className='grid grid-cols-12 gap-5 mt-10'>
                <div className='col-span-12 md:col-span-6 lg:col-span-3 shadow-lg min-h-fit'>
                    <div className='w-full px-5 py-5 bg-white border-b-2 border-gray-300 rounded-t-md'>
                        <h1 className='rounded-md mt-10 text-base xl:text-xl font-medium text-[#8259D9] bg-[#F2EEFB] p-2 px-5 w-fit'>Free</h1>
                        <h1 className='font-bold text-2xl xl:text-4xl mt-6'>₹ {getPlanDataNew?.data?.data?.result[0]?.defaultPrice} <span className='font-normal text-xl'>/ Per Month</span></h1>
                    </div>
                    <div className='px-5 py-5 min-h-fit bg-white rounded-b-md flex flex-col justify-between'>
                        <div>
                            {getPlanDataNew?.data?.data?.result[0]?.features?.map((item, i) => (
                                <div key={i} className={`py-3 xl:py-4 w-full flex`}  >
                                    <span><FaCircleCheck className='mt-[2px] h-4 w-4 mr-2' /></span>
                                    <p className='text-sm xl:text-base'>{item?.description}</p>
                                </div>
                            ))}
                        </div>
                        <button className='bg-primary text-white p-2 xl:p-3 mt-5 text-sm xl:text-base w-full border-none font-medium'>Get Plan Now</button>
                    </div>
                </div>
                <div className='col-span-12 md:col-span-6 lg:col-span-3 shadow-lg'>
                    <div className='w-full px-5 py-5 bg-white border-b-2 border-gray-300 rounded-t-md'>
                        <h1 className='rounded-md mt-10 text-base xl:text-xl font-medium text-[#8259D9] bg-[#F2EEFB] p-2 px-5 w-fit'>Starter</h1>
                        <h1 className='font-bold  text-2xl xl:text-4xl  mt-6'>₹ {getPlanDataNew?.data?.data?.result[1]?.defaultPrice} <span className='font-normal text-xl'>/ Per Month</span></h1>
                    </div>
                    <div className='px-5 py-5 min-h-fit bg-white rounded-b-md flex flex-col justify-between'>
                        <div>
                            <div>
                                {getPlanDataNew?.data?.data?.result[1]?.features?.map((item, i) => (
                                    <div key={i} className={`py-3 xl:py-4 w-full flex`}  >
                                        <span><FaCircleCheck className='mt-[2px] h-4 w-4 mr-2' /></span>
                                        <p className='text-sm xl:text-base'>{item?.description}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <button className='bg-primary text-white p-2 xl:p-3 mt-5 text-sm xl:text-base w-full border-none font-medium'>Get Plan Now</button>
                    </div>
                </div>
                <div className='col-span-12 md:col-span-6 lg:col-span-3 shadow-lg'>
                    <div className='col-span-12 md:col-span-6 lg:col-span-4'>
                        <div className='w-full px-5 py-5 bg-white border-b-2 border-gray-300'>
                            <div className='flex items-center justify-between mt-10'>
                                <h1 className='rounded-md text-base xl:text-xl font-medium text-[#E59215] bg-[#FCF4E7] p-2 px-5 w-fit'>Basic</h1>
                                {/* <img className='h-8' src={icon} alt="icon1" /> */}
                            </div>
                            <h1 className='font-bold text-2xl xl:text-4xl mt-6'>₹ {getPlanDataNew?.data?.data?.result[2]?.defaultPrice} <span className='font-normal text-xl'>/ Per Month</span></h1>
                        </div>
                        <div className='px-5 py-5 min-h-fit bg-white rounded-b-md flex flex-col justify-between'>
                            <div>
                                {getPlanDataNew?.data?.data?.result[2]?.features?.map((item, i) => (
                                    <div key={i} className={`py-3 xl:py-4 w-full flex`}  >
                                        <span><FaCircleCheck className='mt-[2px] h-4 w-4 mr-2' /></span>
                                        <p className='text-sm xl:text-base'>{item?.description}</p>
                                    </div>
                                ))}
                            </div>
                            <button className='bg-primary text-white p-2 xl:p-3 mt-5 text-sm xl:text-base w-full border-none font-medium'>Get Plan Now</button>
                        </div>
                    </div>
                </div>
                <div className='col-span-12 md:col-span-6 lg:col-span-3 shadow-lg'>
                    <div className='col-span-12 md:col-span-6 lg:col-span-4'>
                        <div className='w-full px-5 py-5 bg-white border-b-2 border-section2'>
                            <h1 className='rounded-md mt-10 text-base xl:text-xl font-medium text-[#00CC67] bg-[#E7F8F0] p-2 px-5 w-fit'>Premium</h1>
                            <h1 className='font-bold text-2xl xl:text-4xl mt-6'>₹ {getPlanDataNew?.data?.data?.result[3]?.defaultPrice} <span className='font-normal text-xl'>/ Per Month</span></h1>
                        </div>
                        <div className='px-5 py-5 min-h-fit bg-white rounded-b-md flex flex-col justify-between'>
                            <div>
                                <div>
                                    {getPlanDataNew?.data?.data?.result[3]?.features?.map((item, i) => (
                                        <div key={i} className={`py-3 xl:py-4 w-full flex`}  >
                                            <span><FaCircleCheck className='mt-[2px] h-4 w-4 mr-2' /></span>
                                            <p className='text-sm xl:text-base'>{item?.description}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <button className='bg-primary text-white p-2 xl:p-3 mt-5 text-sm xl:text-base w-full border-none font-medium'>Get Plan Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PackageAndPlan

import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import App from '../App'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Feature from '../pages/feature/Feature'
import BookDemo from '../pages/demo/BookDemo'
import ScrollToTop from '../components/ScrollToTop'
import FeaturesBottom from '../components/FeaturesBottom'
import Drawer from '../components/Drawer'

function AppRouter() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Navbar />
            <Drawer />
            <Routes>
                <Route path='/' element={<App />} />
                <Route path='/feature/:id' element={<Feature />} />
                <Route path='/schedule-demo' element={<BookDemo />} />
            </Routes>
            <Footer />
            <FeaturesBottom />
        </BrowserRouter>
    )
}

export default AppRouter

import React from 'react'
import { BsArrowRight } from 'react-icons/bs'

function NewsLetterSection() {
    return (
        <div className='px-[8%] py-[5%]'>
            <div className='bg-newsletter w-full bg-cover p-5 lg:p-20 rounded-3xl flex flex-col lg:flex-row items-center justify-between'>
                <div className='flex flex-col items-center justify-center lg:items-start'>
                    <div class="text-xl text-center lg:text-2xl xl:text-3xl font-medium w-fit bg-gradient-to-r from-white to-[#164E63] bg-clip-text text-transparent">
                        Simplify your construction building process
                    </div>
                    <div class="text-xl text-center lg:text-2xl xl:text-3xl font-medium w-fit bg-gradient-to-r from-white to-[#164E63] bg-clip-text text-transparent">
                        with GET START BUILD.
                    </div>
                </div>
                <div className='flex items-center justify-center flex-wrap mt-5'>
                    <button className='bg-white text-secondary p-3 px-5 rounded-md font-medium flex items-center'>Get Start For Free <span className='ms-2'><BsArrowRight size={20} /></span></button>
                    <button className='bg-none ms-5 text-white p-3 px-5 rounded-md font-medium flex items-center'>Contact Us</button>
                </div>
            </div>
        </div>
    )
}

export default NewsLetterSection

// utils.js

export const convertTo12HourFormat = (time) => {
    const [hours, minutes, seconds] = time.split(':');
    let hour = parseInt(hours, 10);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12;
    hour = hour ? hour : 12; // the hour '0' should be '12'
    const minutesFormatted = minutes.padStart(2, '0');
    return `${hour}:${minutesFormatted} ${ampm}`;
};

export const formatDisplayDate = (date) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
};

export const getDayOfWeek = (date) => {
    const options = { weekday: 'long' };
    return date.toLocaleDateString('en-US', options);
};

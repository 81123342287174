import React from 'react'
import banner from '../../assets/images/Group 1597883243.png'
import icon from '../../assets/images/Group 1597883229.png'
import { Link } from 'react-router-dom'
import { BsArrowRight } from 'react-icons/bs'

function BenifitSection() {
    return (
        <div className='px-[8%] py-[5%]'>
            <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12 lg:col-span-6 flex flex-col justify-center'>
                    <h1 className='text-3xl md:text-4xl md:w-3/4 font-medium leading-[40px] md:leading-[50px]'>How Get Start Build can
                        benefit you?</h1>
                    <div className='mt-5 flex items-center gap-3'>
                        <img className='h-5' src={icon} alt="icon" />
                        <h1 className='text-lg md:text-xl capitalize'>Save up to 7% of your cost</h1>
                    </div>
                    <div className='mt-5 flex items-center gap-3'>
                        <img className='h-5' src={icon} alt="icon" />
                        <h1 className='text-lg md:text-xl capitalize'>Avoid Project Delays</h1>
                    </div>
                    <div className='mt-5 flex items-center gap-3'>
                        <img className='h-5' src={icon} alt="icon" />
                        <h1 className='text-lg md:text-xl capitalize'>Get 100% Accurate Reports</h1>
                    </div>
                    <div>
                        <Link to={'/schedule-demo'}>
                            <button className='px-5 py-3 w-fit mt-5 text-sm rounded-sm bg-primary text-white flex items-center'>Request Demo <span className='ms-1'><BsArrowRight /></span></button>
                        </Link>
                    </div>
                </div>
                <div className='col-span-12 lg:col-span-6'>
                    <img className='object-contain' src={banner} alt="banner" />
                </div>
            </div>
        </div>
    )
}

export default BenifitSection

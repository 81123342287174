import React, { useEffect, useState } from 'react'
import { tabData } from '../../constants/homeData'
import bannerImg from '../../assets/images/featuresTab/Group 1597882892.png'
import { BsArrowUpRight } from 'react-icons/bs'
import { navMockData } from '../../components/Navbar'
import { featuresMockData } from '../../constants/featuresData'

function FeatureTab() {
    const [activeTab, setActiveTab] = useState(navMockData[0]?.id)
    const [data, setData] = useState()

    useEffect(() => {
        const filteredData = featuresMockData.find(item => item.id === activeTab)
        setData(filteredData)
    }, [activeTab])

    return (
        <>
            <div className='px-[8%] py-[5%] bg-primary'>
                {/* <div className='flex items-center justify-center'>
                    <h1 className='text-2xl text-center lg:text-3xl'>Powerful Features to Boost Your Construction Projects</h1>
                </div> */}
                <div className='flex justify-between items-start md:items-center'>
                    <div className='w-3/4'>
                        <h1 className='text-3xl lg:text-4xl xl:text-5xl text-white font-medium'> Powerful Features of GSB</h1>
                        <p className='text-base lg:text-lg xl:text-xl text-slate-200 mt-3'>is a long established fact that a reader will be distracted by the readable. </p>
                    </div>
                    <div className='h-14 w-14  xl:h-16 xl:w-16 cursor-pointer group bg-white text-primary rounded-full flex justify-center items-center'>
                        <span className='text-2xl xl:text-3xl group-hover:xl:text-4xl group-hover:lg:text-3xl transition-all duration-200 ease-out'><BsArrowUpRight /></span>
                    </div>
                </div>
                <div className='bg-slate-200 shadow p-3 lg:p-4 xl:p-3 rounded-md flex items-center justify-between my-6 lg:mt-10 lg:mb-10 overflow-x-auto'>
                    {navMockData?.map((item, i) => (
                        <div onClick={() => setActiveTab(item?.id)} key={i} className={`rounded-md cursor-pointer  p-3 px-8 text-xs min-w-fit  md:px-14 xl:px-12 transition-all duration-300 ease-out md:text-sm xl:text-base ${activeTab === item?.id ? 'bg-primary text-white' : ''}`}>
                            <p>{item.title}</p>
                        </div>
                    ))}
                </div>
                <div className='grid grid-cols-12 gap-4 '>

                    <div className='col-span-12 lg:col-span-6'>
                        <img className='object-contain w-full' src={data?.banner} alt="banner" />
                    </div>
                    <div className='col-span-12 lg:col-span-6'>
                        <h1>{data?.title}</h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FeatureTab
